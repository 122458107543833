import React from 'react';
import { graphql, StaticQuery } from 'gatsby'; 

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"
import Pagination from "../components/pagination"
import { globalPostsPerPages } from "../components/variables";

const Blog = () => {
  const postsPerPage = globalPostsPerPages;
  let numberOfBlogPages

  return (
    <Layout>
      <SEO/>
      <StaticQuery query={aboutQuery} render={data => {
        numberOfBlogPages = Math.ceil(data.allMarkdownRemark.totalCount / postsPerPage)
        console.log(numberOfBlogPages);
        return (
          <div>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <Post
                preSlug="/blog/"
                key={node.id}
                title={node.frontmatter.title}
                author={node.frontmatter.author}
                slug={node.fields.slug}
                date={node.frontmatter.date}
                body={node.excerpt}
                tags={node.frontmatter.tags}
              />
            ))}
            {data.allMarkdownRemark.totalCount > globalPostsPerPages ? 
              (<Pagination currentPage={1} numberOfPages={numberOfBlogPages} postType={"blog"}/>) :
              (<></>)
            }
          </div>  
        )
      }} />
    </Layout>
  )
}

const aboutQuery = graphql`
query aboutQuery {
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {fileAbsolutePath: {regex: "/src/content/blog/"}, frontmatter: {postType: {eq: "blog"}}}
    limit: 15
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMM Do YYYY")
          author
          postType
          tags
        }
        fields {
          slug
        }
        excerpt(pruneLength: 250)
      }
    }
  }
}
`

export default Blog