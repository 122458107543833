import React from 'react';
import { Link } from 'gatsby';

import { Pagination, PaginationItem } from "./pagination.js";
import "./pagination.scss";

const PaginationComponent = ({ currentPage, numberOfPages, postType}) =>  {
    const isFirts = currentPage === 1;
    const isLast = currentPage === numberOfPages;
    const previousPage = currentPage - 1 === 1 ? `/${postType}/` : `/${postType}/` + (currentPage - 1).toString();
    const nextPage = `/${postType}/` + (currentPage + 1).toString();
    
    return (
        <Pagination>
            {isFirts ?  (
                <Link className="pagination-link-disabled" to="/"> 
                    <PaginationItem disabled> 
                        {`<<`} 
                    </PaginationItem> 
                </Link>
            ) : (    
                <PaginationItem>
                    <Link className="pagination-link" to={previousPage}>{`<<`}</Link>
                </PaginationItem>
            )}

            {Array.from({ length: numberOfPages }, (_, i) => currentPage === i + 1 ? (
                <Link className="pagination-link-active" to={`/${i === 0 ? `${postType}/` : `${postType}/` + (i + 1)}`}>
                    <PaginationItem active key={`page-number${i + 1}`}>
                        {i + 1}
                    </PaginationItem>
                </Link>
            ) : (
                <Link className="pagination-link" to={`/${i === 0 ? `${postType}/` : `${postType}/` + (i + 1)}`}>
                    <PaginationItem key={`page-number${i + 1}`}>{i + 1}</PaginationItem>
                </Link>
            ))}

            {isLast ? (
                <Link className="pagination-link-disabled" to="/"> 
                    <PaginationItem disabled> 
                        {`>>`} 
                    </PaginationItem>
                </Link>
            ) : (    
                <Link className="pagination-link" to={nextPage}> 
                    <PaginationItem>
                        {`>>`}
                    </PaginationItem>
                </Link>
            ) }
        </Pagination>
    )
}

export default PaginationComponent;