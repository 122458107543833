import React from 'react';
import { Link } from 'gatsby';

import "./post.scss";
import Tags from '../tags';

const Post = ({ preSlug, title, author, slug, date, body, tags }) => {
    return (
        <div className="post">
            <div className="post-header">
                <Link className="post-link" to={`${preSlug}${slug}`}>{title}</Link>
                <div className="post-date">{date}</div>
            </div>
            <div className="card-text">{body}</div>
            <Tags tags={tags}/>
        </div>
    )
}

export default Post;