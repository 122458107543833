import React from 'react';
import { Link } from 'gatsby';

import { slugify } from "../../util/utilityFunctions";
import "./tags.scss"

const Tags = ({ tags }) => {
    return (
        <div className="tag-wrapper">
            <ul className="post-tags">
                {tags.map(tag => (
                    <li>
                        <Link className="post-link-tag" to={`/tags/${slugify(tag)}`}>
                            <div className="tag">{tag}</div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Tags;