import styled, { css } from "styled-components"

export const Pagination = styled.div`
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: -20px;
`

export const PaginationItem = styled.div`
    margin: 5px;
    font-size: 0.8rem;

${props => props.disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`